// Vendors

// Angular 2
// import '@angular/platform-browser';
// import '@angular/core';
// import '@angular/common/http';
// import '@angular/router';
//

// RxJS 5
// import 'rxjs/Rx';


// For vendors for example jQuery, Lodash, angular2-jwt import them here
// Also see src/typings.d.ts as you also need to run `typings install x` where `x` is your module
require('bootstrap/dist/css/bootstrap.css');
require('font-awesome/css/font-awesome.css');
require('rdash-ui/dist/css/rdash.css');
require('nouislider/distribute/nouislider.min.css');
